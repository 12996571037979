.intrinsic-container {
    position: relative;
    height: 0;
    overflow: hidden;
  }
  /* 16x9 Aspect Ratio */
  
  .intrinsic-container-16x9 {
    padding-bottom: 56.25%;
  }
  /* 4x3 Aspect Ratio */
  
  .intrinsic-container-4x3 {
    padding-bottom: 75%;
  }
  
  .intrinsic-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.isBrowserView,
.isMobileView {
    display: flex !important;
    justify-content: center;
}

.console-container {
    padding: 16px 0 30px !important;
    width: 50%;
    &:nth-child(2) {
        border-left: 1px solid red;
    }
    // .landscape-vid,
    // .landscape-bet-control {
    //     width: 100%;
    // }
}

@media only screen and (max-width: 560px) {
    // .fightNoStatus,
    // .currentFightStatus {
    //     padding: 12px 10px !important;
    // }
    .isHideWide {
        display: none !important;
    }
    .isHideMobile {
        display: block !important;
    }
    .odds-note {
        .draw-marquee {
            width: 100% !important;
        }
    }
    .tayaCornerFont {
        font-size: 12px !important;
    }
    .possibleWinningStatus {
        .centerCustom {
            text-align: center !important;
        }
    }
}

@media only screen and (max-width: 600px) {
    header {
        margin-bottom: 0 !important;
    }

    #arena-player {
        height: 226px !important;
    }
    .console-container {
        width: 100% !important;
        padding: 0 !important;
    }

    .odds-note {
        .draw-marquee {
            width: 100%;
        }
    }
    .userIdCode {
        span {
            font-size: 22px !important;
        }
    }
    .tayaCornerFont {
        font-size: 12px !important;
    }
    .possibleWinningStatus {
        .centerCustom {
            text-align: center !important;
        }
    }
}

@media only screen and (max-width: 960px) {
    .main-stream-container {
        .video-js {
            height: 180px !important;
        }
        .video-stream-iframe-container {
            padding-bottom: 49.45% !important;
        }
    }
    .odds-note {
        .draw-marquee {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1266px) {
    .main-stream-container {
        .video-js{
            height: 236px !important;
        }
        .video-stream-iframe-container {
            padding-bottom: 50% !important;
        }
    }
    // .fightNoStatusMob {
    //     padding: 10px !important;
    // }
    .odds-note {
        .draw-marquee {
            width: 100%;
        }
    }
}

// -----------------------------------------------------------------------------------------//

@media screen and (orientation: portrait) {
    .landscapeScrollStyle {
        display: block;
    }
    .isMobileView {
        .mobile-console-container {
            width: 100% !important;
            .landscape-vid,
            .landscape-bet-control {
                width: 100% !important;
                // overflow-y: scroll;
                // max-height: 100vh;
            }
        }
    }
}

@media screen and (orientation: landscape) {
    // html, body {
    //     margin: 0; 
    //     height: 100%; 
    //     overflow: hidden
    // }
    .landscapeScrollStyle {
        display: none;
    }
    .isMobileView {
        .mobile-console-container {
            width: 100% !important;
            .landscape-vid,
            .landscape-bet-control {
                width: 50% !important;
            }

            // .fixedVideoDiv {
            //     position: relative;
            //     left: 0;
            //     height: 100%;
            // }

            .landscape-vid {
                position: relative;
                left: 0;
                top: 0;
                height: 100%;
            }

            .landscape-bet-control {
                position: relative;
                overflow-y: scroll;
                max-height: 100vh;
                // margin-bottom: 180px;
                top: 0;
                right: 0;
            }
        }
    }
}