// Branding
// ------------------------------------------------------------------------------------------------------------------------------------------
.brand-header {
    background-color: $backgroundColor;
}
.MuiAppBar-colorPrimary {
    background-color: $backgroundColor !important;
}

.odds-note {
    background: $black;
}

.evenMatch {
    background-color: $backgroundColor;
    color: white; 
    padding: 8px 16px;
    width: 100%;
}

.fightStatusContainer {
    background-color: $backgroundColor;
    border-radius: 12px;
    margin-bottom: 16px;
    padding: 15px;
    flex-grow: 1;
    max-width: unset;
    .currentFightStatus {
        display: flex;
        text-align: right;
    }
}

.arenatabBG {
    background-color: $brandRed;
}

 

// ------------------------------------------------------------------------------------------------------------------------------------------




.isHideMobile {
    display: none !important;
}

.srv-validation-message{
    color: #353535;
    background-color: #f3e7e7;
    border: 1px solid #ca8383;
    font-size: 11px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin-top: -6px;
    margin-bottom: 5px;
    padding: 2px;
}
    @-webkit-keyframes blinkingFrame {
        0%, 49% {
            background-color: rgb(117, 209, 63);
            border: 3px solid #e50000;
        }
        50%, 100% {
            background-color: #e50000;
            border: 3px solid rgb(117, 209, 63);
        }
    }

// Draw Marquee Styles
.odds-note {
    overflow: hidden;
    position: relative;
    .draw-marquee {
        // height: 32px;
        color: white;
        padding: 8px 0;
        margin: 0 auto !important;
        overflow: hidden;
        position: relative;
        width: 84%;
        p {
          font-size: 11px;
          width: 90%;
          height: 100%;
          margin: 0;
          text-align: center;
          -moz-transform: translateX(100%);
          -webkit-transform: translateX(100%);
          transform: translateX(100%);
          -moz-animation: scroll-left 2s linear infinite;
          -webkit-animation: scroll-left 2s linear infinite;
          animation: scroll-left 20s linear infinite;
      }
    }
}

  @-moz-keyframes scroll-left {
      0% {
          -moz-transform: translateX(100%);
      }
      100% {
          -moz-transform: translateX(-100%);
      }
  }

  @-webkit-keyframes scroll-left {
      0% {
          -webkit-transform: translateX(100%);
      }
      100% {
          -webkit-transform: translateX(-100%);
      }
  }

  @keyframes scroll-left {
      0% {
          -moz-transform: translateX(100%);
          -webkit-transform: translateX(100%);
          transform: translateX(100%);
      }
      100% {
          -moz-transform: translateX(-100%);
          -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
      }
  }

    @keyframes blinkingRed {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: rgba(226, 32, 48, 0.9);
        }
    } 

    @keyframes blinkingBlue {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: rgba(48, 93, 255, 0.9);
        }
    }  

    @keyframes blinkingDraw {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: rgba(86, 187, 39, 0.9);
        }
    }  

.console-container {
    padding: 16px 0 30px !important;
    width: 50%;
    &:nth-child(2) {
        border-left: 1px solid $primary-color !important;
    }
}    

.bet-console-container {
    padding: 16px;
}  

.DRAW-draw {
    display: none;
}

.MERON-draw {
    display: block;
}

.WALA-draw {
    display: block;
}

.DRAW-draw-show {
    display: block;
}
.WALA-draw-show {
    display: none;
}
.MERON-draw-show {
    display: none;
}
    @keyframes gradient-slide {
        0% {
            background-position: 100% 50%
        }

        to {
            background-position: 0% 50%
        }
    }

.loading {
    @extend %loading;
}
    %loading {
        -webkit-animation: gradient-slide 2.2s ease infinite;
        animation: gradient-slide 2.2s ease infinite;
        background: 0 0/300% 300% linear-gradient(90deg, #2d2d2d 40%, #252525 50%, #2d2d2d 60%);
    }

.main-stream-container {
    background-color: black;
    padding: 0;
    position: relative;
    .video-js {
        height: 300px;
        z-index: 88;
    }
    .iframe-vid {
        z-index: 88;
        .text-center {
            img {
                width: 100% !important;
            }
        }
        #videoPlayerX {
            height: 315px;
            video {
                height: 100% !important;
                vertical-align: middle !important;
            }
        }
    }
}

#arena-player {
    // height: 53%;
    height: 300px;
}

.lastCall-LAST_CALL {
    animation: blinkingLastCall 1.5s infinite;
}
    @keyframes blinkingLastCall {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

.intrinsic-container {
    #videoplayer {
        width: 100%;
        height: 300px !important;
    }
}

.userIdCode {
    position: absolute;
    z-index: 777;
    animation: fadeObj 30s infinite ease-in-out;
    -webkit-animation: fadeObj 30s infinite ease-in-out;
    -moz-animation: fadeObj 30s infinite ease-in-out;
    -o-animation: fadeObj 30s infinite ease-in-out;
    -ms-animation: fadeObj 30s infinite ease-in-out;
    span {
        color: white !important;
        opacity: 0.4 !important;
        font-size: 26px;
    }
    // top: 16%;
    // left: 6%;
}
    @-webkit-keyframes fadeObj {
        0% { /*Top Left*/
            opacity:1;
            top: 18%;
            left: 6%;
        }
        
        20% { /*Top Left*/
            opacity:1;
            top: 18%;
            left: 8%;
        }
        22% { /*Top Left*/
            opacity:0;
            top: 18%;
            left: 6%;
        }
        24% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        26% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        
        40% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        42% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        44% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        46% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        
        70% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        72% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        74% {
            opacity:0;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        76% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        
        94% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        96% {
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        98% {
            opacity:0;
            top: 16%;
            left: 6%;
        }
        100% {
            opacity:1;
            top: 16%;
            left: 6%;
        }
    }

    @keyframes fadeObj {
        0% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        
        20% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        22% { /*Top Left*/
            opacity:0;
            top: 16%;
            left: 6%;
        }
        24% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        26% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        
        40% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        42% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        44% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        46% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        
        70% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        72% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        74% {
            opacity:0;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        76% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        
        94% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        96% {
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        98% {
            opacity:0;
            top: 16%;
            left: 6%;
        }
        100% {
            opacity:1;
            top: 16%;
            left: 6%;
        }
    }

    @-moz-keyframes fadeObj {
        0% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        
        20% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        22% { /*Top Left*/
            opacity:0;
            top: 16%;
            left: 6%;
        }
        24% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        26% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        
        40% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        42% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        44% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        46% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        
        70% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        72% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        74% {
            opacity:0;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        76% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        
        94% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        96% {
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        98% {
            opacity:0;
            top: 16%;
            left: 6%;
        }
        100% {
            opacity:1;
            top: 16%;
            left: 6%;
        }
    }

    @-o-keyframes fadeObj {
        0% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        
        20% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        22% { /*Top Left*/
            opacity:0;
            top: 16%;
            left: 6%;
        }
        24% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        26% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        
        40% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        42% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        44% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        46% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        
        70% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        72% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        74% {
            opacity:0;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        76% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        
        94% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        96% {
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        98% {
            opacity:0;
            top: 16%;
            left: 6%;
        }
        100% {
            opacity:1;
            top: 16%;
            left: 6%;
        }
    }

    @-ms-keyframes fadeObj {
        0% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        
        20% { /*Top Left*/
            opacity:1;
            top: 16%;
            left: 6%;
        }
        22% { /*Top Left*/
            opacity:0;
            top: 16%;
            left: 6%;
        }
        24% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        26% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        
        40% { /*Bottom Right*/
            opacity:1;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        42% { /*Bottom Right*/
            opacity:0;
            top: auto;
            left: auto;
            bottom: 16%;
            right: 6%;
        }
        44% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        46% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        
        70% {
            opacity:1;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        72% {
            opacity:0;
            top: auto;
            left: 6%;
            bottom: 16%;
            right: auto;
        }
        74% {
            opacity:0;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        76% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        
        94% {
            opacity:1;
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        96% {
            top: 16%;
            left: auto;
            bottom: auto;
            right: 6%;
        }
        98% {
            opacity:0;
            top: 16%;
            left: 6%;
        }
        100% {
            opacity:1;
            top: 16%;
            left: 6%;
        }
    }


// Arena Tab Status
#arenaInnerTabLastCallNotif {
    // top: -15px;
    background-color: #333746;
    border-radius: 6px;
    color: #f4fe07;
    font-size: 10px;
    font-family: roboto;
    font-weight: lighter;
    text-transform: uppercase;
    text-align: center;
    padding: 8px;
    position: absolute;
    z-index: 9;
    animation: blinkingLastCallTab 1.8s infinite;
    &::before {
        bottom: 0;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: rgba(0,0,0,.25);
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
    }
    &::after {
        bottom: -6px;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: #333746 !important;
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
        left: 36%;
    }
}
    @keyframes blinkingLastCallTab {
        0% {
            opacity: 0;
            top: -36px;
        }

        50% {
            top: -46px;
            opacity: 1;
        }
        100% {
            top: -36px;
            opacity: 0;
        }
    }

.arenaTabStatus-LAST_CALL {
    display: block !important;
}

.arenaTabStatus-FIGHT,
.arenaTabStatus-RESULT,
.arenaTabStatus-BET {
    display: none !important;
}

// #arenaButton { 
//     .Mui-disabled {
//         .arenaTabStatus-LAST_CALL {
//             display: block !important;
//         }
//     }
// }


.arenaTabStatusNotif-LAST_CALL {
    display: block !important;
}

.arenaTabStatusNotif-FIGHT,
.arenaTabStatusNotif-BET,
.arenaTabStatusNotif-RESULT,
.arenaTabStatusNotif-WAIT {
    display: none !important;
}

.arenaTabSelectContainer {
    text-align: center;
}

.possibleWinningStatus_FIGHT, .possibleWinningStatus_RESULT {
    display: block !important;
}

// .betControlNonPointsIsHide {
//     display: none;
// }

// .betControlWithPointsIsHide {
//     display: none;
// }

.betControlIsHide {
    display: none;
}

.isbetControNonPointslEnabled {
    display: block !important;
}

.isbetControNonPointslDisabled {
    display: block !important;
}

.video-block-overlay {
    position: absolute;
    background-color: #555555;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px 0;
    color: white;
    font-size: 13px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    z-index: 888;
}

@media screen and (max-width: 1920px) {
    .video-block-overlay{
        padding: 8px 0 !important;
    } 
}

@media screen and (max-width: 1280px) {
    .video-block-overlay, .video-block-overlay2 {
        padding: 8px 0 !important;
    }
}

@media screen and (max-width: 1366px) {
    .video-block-overlay {
        padding: 14px 0 !important;
    }
}

@media screen and (max-width: 1440px) {
    .video-block-overlay {
        padding: 14px 0;
    }
}