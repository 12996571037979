.mobile-console-container {
    padding: 0 !important;
    width: 100%;

    .landscape-vid,
    .landscape-bet-control {
        width: 100%;
    }
    .fixedVideoDiv {
        position: fixed;
        z-index: 8888888;
        top: 0;
        margin-bottom: 16px;
    }
    .scrollPadding {
        position: relative;
        top: 200px;
        padding-bottom: 60px;
    }
}