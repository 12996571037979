html, body{
  // height: 100%;
  margin: 0px;
  padding: 0px;
  &::before, &::after{
    box-sizing: border-box;
    outline: none;
  }
  iframe {
    position: relative !important;
  }
}

// #root, .app-root{
  // height: 100%;
// }

.remove-arrows-spinners {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}